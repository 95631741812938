<template>
	<div class="tunnista">
		<div class="container-fluid">
			<div class="row">
				<div class="col"></div>
				<div class="col-sm-8">
					<img class="img-fluid fluid-grow" alt="" src="../../assets/solmu.webp" style="width: 100%;">
				</div>
				<div class="col"></div>
			</div>
		</div>
		<div class="container-fluid" style="margin-top: 25px;">
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<h2>Piilotyöpaikat</h2>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<p>Työttömäksi jääneelle voi näinä aikoina tulla ikävänä yllätyksenä se, ettei uuteen työpaikkaan siirtyminen enää olekaan itsestäänselvyys. Satojen hakemusten kirjoittamisen lisäksi voikin tarvita asennemuutosta siihen, miten itseään markkinoisi uudelle työnantajalle, sillä SITRAN mukaan suurin osa (noin 70%) Suomen työpaikoista ei koskaan tule julkiseen hakuun (kts.www.sitra.fi). Jotta osaisi hahmottaa myös omia työelämän rajoituksiaan, on hyvä tehdä ´kuntotesti´ eli miten paljon joustavuutta minulla työntekijänä on.</p>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<h3>TYÖNANTAJAT:Kenelle ja mitä kautta työt sitten jaetaan?</h3>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<p>Sijaisuuksia annetaan mieluimmin jotalon sisällä olleille, työvoimaa vuokraavien yritysten kautta tai suoraan´tutulle hakijalle´. Tämä on työnantajalle se edullisin, sujuvin jaodotuksiltaan se luotettavin keino välttää palkkauksen haaksirikottuntemattoman henkilön palkkauksessa. Huonon työntekijän palkkaaminen onerityisesti pienille yrityksille erittäin suuri riski, joten tätä hutilyöntiäei ole vara tehdä, joten luottamuksen kasvattaminen kaikin keinoin on tärkeää.</p>
					<p>Toisin sanoen, on hyvä miettiä entisiä työnantajiasi, kouluttajiasi ja tuttavuuksiaesimiesasemassa ja kysyä: Kenen kanssa sinä haluaisit työskennellä? Keneen luotit? Kuka suosittelisi sinua varauksettomasti? Kehen voisit ottaa yhteyttä? Mieti, keitä toimijoita ja yrityksiä on alallasi? Minkälaisissa yrityksissähaluaisit työskennellä? Mitkä ovat ne vahvuutesi, joista työnantaja olisi kiinnostunut ja joita voit heille tarjota? Missä laitoksissa alasi tutkimusta tehdään? Keitä Suomen Akatemia / TEKES tällä hetkellä rahoittavat?
					</p>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<h3>YHTEYDENOTOT</h3>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<p>Tee lista näistä itsellesi mahdollisista paikoista, joissa tiedät, ettäkoulutus- ja kokemustaustallasi olevia ihmisiä työskentelee. LinkedIn on hyväsivusto, kun etsit yritysten toimihenkilöitä ja vaikkei sähköpostiosoite olisijulkinen, sitä voi Googlata (tai sitten osoitteen yleismuoto on yrityksensivustolla mainittu (etunimi.sukunimi@Company.com). Voit luonnollisesti ottaayhteyttä suoraan esim. professoreihin (joskin nämä eivät välttämättä vastaa,joten tässä pitää olla kärsivällinen), toimitusjohtajiin tai jos on kyseessäisompi yritys henkilöstöosastoon (HR /human resources), jossa HR-päällikkö onse, jolle voit soittaa tai lähettää sähköpostiviestin kysyäksesi työpaikkaa.Älä jaarittele, vaan mene puhuessasi suoraan asiaan ja kysy, josko vastaajallaolisi 10-15 minuuttia aikaa jutella kanssasi ja mieti ennakkoon ´hissipuheesi´.Ilmaise innokkuutesi, jos olet aidosti kiinnostunut (ja jos tunnet jonkunyrityksen sisältä on ensin kysyttävä tietenkin lupa nimen mainitsemiseen) jakerro missä sinä olet erityisen hyvä ja kerro missä sinä olet erityisen hyvä ja mihin suuntaan toivot urasi kehittyvän.</p>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<h3>NÄKYVYYS: Tietävätkö alasi tuttavuudet, että etsit töitä?</h3>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<p>Tilanteestasi voit kertoa sekä virallisella (LinkedIn/ ”looking for new opportunities”), että tuttavillesi epävirallisimmilla some-alustoilla tai muissa kohtaamisissa. Jollet ole perehtynyt ja päivittänyt LinkedIn-sivujasi, on aika tehdä se - sivuston rakentamiseen kannattaa panostaa: LinkedIn-guru Tom Laineen mukaan (Urasampo Webinar 18.3.2020), 31.4% käyttäjistä Suomessa on saanut työpaikkatarjouksen LinkedIn-sivuston kautta, joten näin merkittävää kanavaa ei ole vara ohittaa!</p>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<h3>Linkit:</h3>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<p>SITRA / Suomen itsenäisyyden juhlarahasto (Julkisoikeudellinen eduskunnan valvoma rahasto)/ Finnish Innovation Fund<br> 
					<a href="www.sitra.fi">www.sitra.fi</a><br>
					<a href="https://kuntotesti.sitra.fi/">https://kuntotesti.sitra.fi/</a><br>
					<a href="https://fi.linkedin.com/">https://fi.linkedin.com/</a><br> 
					(Microsoftin omistama verkkoyhteisöpalvelu ja verkostoitumisväline; ammatillisen osaamisen ja koulutuksen esittelyalusta (toistaiseksi englanniksi) / kontaktikanava etc.) <br>
					<a href="https://www.tomlaine.com/blog/linkedin-tilastot-syksy219"> https://www.tomlaine.com/blog/linkedin-tilastot-syksy219</a><br>
					<a href="https://www.somehow.fi/pitaako-linkedin-profiili-olla-suomeksi-vai-englanniksi/">https://www.somehow.fi/pitaako-linkedin-profiili-olla-suomeksi-vai-englanniksi/</a></p>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<h3>Palkkatietoa</h3>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<p>Palkkauksen perustan muodostavat eri alojen työehtosopimukset. Niissä määritellään kunkin alan vähimmäispalkat. Palkkauksessa otetaan huomioon tehtävien vaativuus ja vastuullisuus, työntekijän pätevyys, työn tulos ja sen edellyttämä koulutus.</p>
					<p>Palkka on keskeinen osa työsuhdetta. Kun olet hakemassa uutta tehtävää, saattaa olla hankalaa muodostaa käsitystä, minkä suuruista palkkaa voi pyytää. Palkka kertoo siitä, millä tavalla arvotat ja arvostat omaa osaamistasi. Vastavalmistuneet ja alan vaihtajat: usealla Akavalaisella liitolla on Palkkanosturi-työkalu, joka piirtää palkkakäyrät muuttujavalintojesi perusteella. Palkkanosturissa voit tarkastella useiden taustatekijöiden (esimerkiksi toimiasema, toimiala, työnantajan koko ja työkokemusvuodet) vaikutusta palkkaan samanaikaisesti. Palkkanosturin tiedot perustuvat uusimman työmarkkinatutkimuksen tuloksista laskettuihin matemaattisiin malleihin.</p>
					<p>Jos kuulut akavalaiseen liittoon, pyydä apua liittojen asiantuntijoilta.<br>
					Esimerkiksi Tekniikan akateemiset TEK:llä on “Näin parannat palkkaasi” -opas<br>
					<a href="https://www.tek.fi/fi/palkat">https://www.tek.fi/fi/palkat</a></p>
					<p>Elinkeinoelämän keskusliitto (EK:n) sivuilta löydät työnantajan keräämää tietoa<br>
					<a href="https://ek.fi/mita-teemme/palkat/palkitseminen/">https://ek.fi/mita-teemme/palkat/palkitseminen/</a></p>
					<p>Suomen Yrittäjät kertoo miten palkkaa maksetaan<br>
					<a href="https://www.yrittajat.fi/yrittajan-abc/tyonantajan-abc/tyooikeuden-kysymyksia/maksettavat-palkat-318262">https://www.yrittajat.fi/yrittajan-abc/tyonantajan-abc/tyooikeuden-kysymyksia/maksettavat-palkat-318262</a></p>					
					<p>Työsuojelun sivuilta löydät lisää työsuhteeseen liittyvää tietoa<br>
					<a href="https://www.tyosuojelu.fi/tyosuhde/palkka">https://www.tyosuojelu.fi/tyosuhde/palkka</a></p>

				</div>
				<div class="col"></div>
			</div>
		</div>
		<Social/>
	</div>
</template>

<script>
import Social from '../../components/Social.vue'

export default {
name: 'Piilo',
components: {
    Social
  }
}
</script>

<style type="text/css">
.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>